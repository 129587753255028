/* eslint-disable react-hooks/exhaustive-deps */
'use client';
import clsx from 'clsx';
import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';

import Link from '@/components/core/Link';
import { useAppRouter } from '@/components/hooks/useAppRouter';
import useClientTranslation from '@/components/hooks/useClientTranslation';

import { runsOnServerSide } from '@/app/i18n/client';

const slidesData = [
  {
    img: '/img/banner/gift_promo_5.png',
    text: 'mainSlider.mainSliderText5',
    link: '/game/tables',
    imgItem1: (
      <Image
        priority
        className='object-fit absolute -top-2 right-10 w-[165px] translate-x-0 transform transition-transform duration-300 ease-in-out group-hover:translate-x-[100px] group-hover:scale-[0.9]'
        src='/img/banner/gift_promo_5_1.png'
        alt=''
        width={165}
        height={290}
      />
    ),
    imgItem2: (
      <Image
        priority
        className='object-fit absolute left-16 top-28 w-[80px] translate-x-0 transform transition-transform duration-300 ease-in-out group-hover:translate-x-[-70px]'
        src='/img/banner/gift_promo_5_2.png'
        alt=''
        width={80}
        height={156}
      />
    ),
    text1: 'mainSlider.casinoText1',
    text2: 'mainSlider.casinoText2',
    buttonText: 'mainSlider.playNow',
  },
  {
    img: '/img/banner/gift_promo_4.png',
    text: 'mainSlider.mainSliderText4',
    link: '/game/sports',
    imgItem1: (
      <Image
        priority
        className='object-fit absolute right-10 top-0 z-[1] w-[165px] translate-x-0 transform transition-transform duration-300 ease-in-out group-hover:translate-x-[-200px] group-hover:translate-y-[10px] group-hover:scale-[0.8]'
        src='/img/banner/gift_promo_4_2.png'
        alt=''
        width={168}
        height={250}
      />
    ),
    imgItem2: (
      <Image
        priority
        className='object-fit absolute -top-5 left-0 z-[0] w-[220px] translate-x-0 transform transition-transform duration-300 ease-in-out group-hover:translate-x-[180px] group-hover:translate-y-[10px] group-hover:scale-[0.7]'
        src='/img/banner/gift_promo_4_1.png'
        alt=''
        width={220}
        height={277}
      />
    ),
    text1: 'mainSlider.sportText1',
    text2: 'mainSlider.sportText2',
    buttonText: 'mainSlider.betNow',
  },
  {
    img: '/img/banner/gift_promo_3.png',
    text: 'mainSlider.mainSliderText3',
    link: '/game/slots',
    imgItem1: (
      <Image
        priority
        className='object-fit absolute right-14 top-[-17.5px] z-[0] w-[165px] translate-x-0 transform transition-transform duration-300 ease-in-out group-hover:translate-x-[110px]'
        src='/img/banner/gift_promo_3_2.png'
        alt=''
        width={165}
        height={273}
      />
    ),
    imgItem2: (
      <Image
        priority
        className='object-fit absolute left-10 top-28 w-[140px] translate-x-0 transform transition-transform duration-300 ease-in-out group-hover:translate-x-[-90px]  group-hover:scale-[1.05]'
        src='/img/banner/gift_promo_3_1.png'
        alt=''
        width={140}
        height={130}
      />
    ),
    text1: 'mainSlider.jackpotText1',
    text2: 'mainSlider.jackpotText2',
    buttonText: 'mainSlider.playNow',
  },
  {
    img: '/img/banner/gift_promo_1.png',
    text: 'mainSlider.mainSliderText1',
    link: '/promotion',
    imgItem1: (
      <Image
        priority
        className='object-fit absolute -top-4 right-14 z-[0] w-[145px] translate-x-0 transform transition-transform duration-300 ease-in-out group-hover:translate-x-[120px] group-hover:scale-[0.8]'
        src='/img/banner/gift_promo_1_1.png'
        alt=''
        width={145}
        height={270}
      />
    ),
    imgItem2: (
      <Image
        priority
        className='object-fit absolute left-[10px] top-[100px] w-[160px] translate-x-0 transform transition-transform duration-300 ease-in-out group-hover:translate-x-[-90px] group-hover:scale-[1.05]'
        src='/img/banner/gift_promo_1_2.png'
        alt=''
        width={140}
        height={133}
      />
    ),
    text1: 'mainSlider.promoText1',
    text2: 'mainSlider.promoText2',
    buttonText: 'mainSlider.depositNow',
  },
  {
    img: '/img/banner/gift_promo_2.png',
    text: 'mainSlider.mainSliderText2',
    link: '/game/fishing',
    imgItem1: (
      <Image
        priority
        className='object-fit absolute right-10 top-0 z-[0] w-[230px] translate-x-0 transform transition-transform duration-300 ease-in-out group-hover:translate-x-[150px] group-hover:scale-[0.96]'
        src='/img/banner/gift_promo_2_1.png'
        alt=''
        width={230}
        height={268}
      />
    ),
    imgItem2: (
      <Image
        priority
        className='object-fit absolute left-10 top-24 w-[180px] translate-x-0 scale-[0.8] transform transition-transform duration-300 ease-in-out group-hover:translate-x-[-100px] group-hover:scale-[0.9]'
        src='/img/banner/gift_promo_2_2.png'
        alt=''
        width={144}
        height={105}
      />
    ),
    text1: 'mainSlider.fishingText1',
    text2: 'mainSlider.fishingText2',
    buttonText: 'mainSlider.playNow',
  },
];

const MainSliderV2: React.FC = () => {
  const { t } = useClientTranslation('HomePage');
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  const [isNearRightEdge, setIsNearRightEdge] = useState(false);
  const { router } = useAppRouter();
  const lastElementRef = useRef<any>(null);
  const handleMouseEnter = (index: number) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };

  useEffect(() => {
    if (!lastElementRef.current) return;
    const rect = lastElementRef.current?.getBoundingClientRect();
    const elementRightEdge = rect.right; // Vị trí bên phải của phần tử
    const threshold = 100; // Ngưỡng khoảng cách, ví dụ 10px
    if (!runsOnServerSide)
      setIsNearRightEdge(window.innerWidth - elementRightEdge <= threshold);
  }, [lastElementRef, !runsOnServerSide && window.innerWidth]);

  return (
    <div className='relative mx-auto w-full max-w-[100%]'>
      <div className='flex w-full flex-wrap items-center justify-center'>
        {slidesData.map((data, index) => {
          return (
            <div
              ref={slidesData?.length - 1 === index ? lastElementRef : null}
              key={data.img}
              className={clsx(
                'cursor-hand group relative flex min-h-[320px] w-[320px] justify-center text-center transition-all',
                hoverIndex === null && 'z-[0] scale-[1]',
                hoverIndex !== null &&
                  'scale-[0.75] opacity-[0.5] transition-all',
                hoverIndex === index &&
                  '!z-[1] !w-[320px] !scale-[1.1] !opacity-[1]',
                hoverIndex === slidesData.length - 1 &&
                  isNearRightEdge &&
                  'translate-x-[-150px]'
              )}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              style={{
                transition: 'transform 0.3s ease-in-out',
              }}
              onClick={() => {
                router.push(data.link);
              }}
            >
              <Image
                className='cursor-hand absolute max-w-[300px] rotate-0 scale-100 transition-all duration-300 ease-in-out will-change-transform group-hover:rotate-180 group-hover:scale-0'
                src='/img/banner/circle_bg.png'
                alt=''
                width={300}
                height={264}
                priority
              />

              <Image
                className='cursor-hand absolute left-0 top-[6.5rem] h-[115px] min-w-[380px] scale-x-0 transition-all duration-500 ease-in-out will-change-transform group-hover:scale-x-100 group-hover:delay-0'
                src='/img/banner/gift_bg.png'
                alt=''
                width={380}
                loading='lazy'
                height={115}
              />

              <div className='absolute mx-auto -mt-2 w-full scale-[0] text-center group-hover:bottom-[66px] group-hover:left-[0px] group-hover:scale-[0.5]'>
                <Link href={data.link}>
                  <div className='heartbeat scaleEffect mx-auto flex w-[311px] justify-center hover:animate-none hover:brightness-90 active:translate-y-1'>
                    <div className='effects relative w-[67%]'>
                      <div
                        className='glassEffect flex items-center justify-center overflow-hidden'
                        style={{
                          clipPath:
                            'polygon(6.286% 15.898%,6.286% 15.898%,6.534% 13.321%,6.863% 10.929%,7.268% 8.743%,7.74% 6.783%,8.273% 5.07%,8.86% 3.623%,9.493% 2.462%,10.165% 1.608%,10.869% 1.081%,11.598% 0.901%,98.384% 0.901%,98.384% 0.901%,98.709% 1.017%,99.011% 1.351%,99.284% 1.878%,99.525% 2.573%,99.727% 3.413%,99.887% 4.375%,99.999% 5.433%,100.058% 6.564%,100.06% 7.744%,100% 8.949%,94.17% 85.003%,94.17% 85.003%,93.922% 87.58%,93.592% 89.972%,93.188% 92.158%,92.715% 94.118%,92.182% 95.831%,91.596% 97.278%,90.963% 98.439%,90.291% 99.293%,89.586% 99.82%,88.857% 100%,2.072% 100%,2.072% 100%,1.747% 99.883%,1.445% 99.55%,1.171% 99.023%,0.931% 98.328%,0.728% 97.487%,0.569% 96.526%,0.457% 95.468%,0.398% 94.337%,0.395% 93.157%,0.455% 91.952%,6.286% 15.898%)',
                        }}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='209'
                          height='56'
                          viewBox='0 0 209 56'
                          fill='none'
                        >
                          <path
                            d='M13.0782 8.8236C14.4946 3.89516 19.0029 0.5 24.1308 0.5H204.689C207.016 0.5 208.695 2.7297 208.052 4.96673L195.922 47.1764C194.505 52.1048 189.997 55.5 184.869 55.5H4.31146C1.98388 55.5 0.304722 53.2703 0.947619 51.0333L13.0782 8.8236Z'
                            fill='url(#paint0_linear_330_40588)'
                            stroke='url(#paint1_linear_330_40588)'
                          />
                          <defs>
                            <linearGradient
                              className='animation-button'
                              id='paint0_linear_330_40588'
                              x1='-0.999996'
                              y1='84'
                              x2='171.142'
                              y2='-85.6701'
                              gradientUnits='userSpaceOnUse'
                            >
                              <stop stopColor='#936321' />
                              <stop offset='0.34' stopColor='#D6B25C' />
                              <stop offset='0.485' stopColor='#ECCB77' />
                              <stop offset='0.63' stopColor='#D6B25C' />
                              <stop offset='1' stopColor='#936321' />
                            </linearGradient>
                            <linearGradient
                              id='paint1_linear_330_40588'
                              x1='-0.999994'
                              y1='-11.2'
                              x2='44.1242'
                              y2='170.683'
                              gradientUnits='userSpaceOnUse'
                            >
                              <stop offset='0.0729167' stopColor='#E3A455' />
                              <stop offset='0.244792' stopColor='#F6DBA6' />
                              <stop offset='0.325774' stopColor='#F0BE79' />
                              <stop offset='0.528793' stopColor='#BA7F3B' />
                              <stop offset='0.627652' stopColor='#673D22' />
                              <stop offset='0.773898' stopColor='#EEBC70' />
                              <stop offset='0.921875' stopColor='#3F1F0F' />
                            </linearGradient>
                          </defs>
                        </svg>

                        {/* <div className='inter-bold hover-cursor absolute left-1/2 flex h-[90%] w-full -translate-x-1/2 select-none items-center justify-center text-[20px] font-bold text-[#845D00]'>
                         
                          {t(data.buttonText)}

                        </div> */}
                        <div className='relative top-0 mx-auto mt-4 h-[50px] w-full min-w-[320px]  text-center'>
                          <div className='heartbeat scaleEffect mx-auto flex h-full w-[310px] justify-center hover:animate-none hover:brightness-90 active:translate-y-1'>
                            <div className='effects relative h-full w-[60%]'>
                              <img
                                className='absolute left-0 top-0 h-[50px] w-[200px]'
                                src='/img/common/bg_btn.png'
                                alt=''
                              />
                              <div className='glassEffect flex h-full items-center justify-center'>
                                <div className='inter-bold hover-cursor absolute left-[90px] flex h-[90%] h-full w-full -translate-x-1/2 select-none items-center justify-center text-[20px] font-bold text-[#845D00]'>
                                  {t(data.buttonText)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='cursor-hand absolute left-0 right-0 right-24 top-36 mt-0.5 w-full opacity-0 group-hover:opacity-100'>
                <div className='absolute z-50 mt-[1.1rem] flex w-full items-center justify-center text-xl font-bold text-white'>
                  <span
                    style={
                      {
                        WebkitTextStroke: '1px transparent',
                      } as React.CSSProperties
                    }
                    className='bg-linear-2 text-20  absolute  z-10	h-[35px] !bg-clip-text  !bg-[100%_100%] text-[#D6B25C]'
                  >
                    {t(data.text1)}
                  </span>
                  <span
                    className='bg-linear-2  text-20 h-[35px]	!bg-clip-text  !bg-[100%_100%] text-[#ECCB77]'
                    style={
                      {
                        textShadow: '1px 1px 0px #7B4801',
                      } as React.CSSProperties
                    }
                  >
                    {t(data.text1)}
                  </span>
                </div>

                <div className='text-main-bg text-16'>{t(data.text2)}</div>
              </div>

              <Link href={data.link} aria-label={t(data.text)}>
                {data.imgItem1}
                {data.imgItem2}
              </Link>
              <div className='text-16 mx-auto mt-auto w-[70%] whitespace-break-spaces text-center font-semibold opacity-[1] group-hover:opacity-[0]'>
                {t(data.text)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MainSliderV2;
