/* eslint-disable react-hooks/exhaustive-deps */
'use client';
import clsx from 'clsx';
import { getCookie } from 'cookies-next';
import { motion } from 'framer-motion';
import { throttle } from 'lodash-es';
import { usePathname } from 'next/navigation';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FreeMode } from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';

import { updateQueryStringParameters } from '@/lib/utils';

import { openGameProvider } from '@/components/core/OpenGame';
import Swiper from '@/components/core/Swiper';
import useClientTranslation from '@/components/hooks/useClientTranslation';

import ProviderGames from '@/app/[lng]/(home)/components/Mobile/components/ProviderGames';
import { gameMenuMobile } from '@/constant/gameMenu';

export const LobbyProvider = {
  icon: '/img/menu/active/hot.png',
  iconHide: '/img/menu/fade/hot.png',
  display: '17',
  label: 'header.menuItem13',
  slug: 'hot',
  component: (props: JSX.IntrinsicAttributes) => <ProviderGames {...props} />,
};

const TopMenu: FC<any> = ({
  handleClick,
  className = '',
  menuItemClass = '',
  swiperOptions = {},
  isAnimated = true,
  imgClass = '',
  textClass = '',
  active,
}) => {
  const { t } = useClientTranslation('HomePage');

  const pathname = usePathname();
  const token = getCookie('token');
  const index = gameMenuMobile.findIndex((_) => _.slug === active);
  const activeIndex = index;
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down' | null>(
    'up'
  );

  const lastScrollTop = useRef(
    typeof window !== 'undefined' ? window.scrollY : 0
  );
  const lastScrollTime = useRef(Date.now());

  const handleScroll = () => {
    // if (activeIndex === 0) {
    //   setScrollDirection('up');
    //   return;
    // }

    const currentScrollTop = typeof window !== 'undefined' ? window.scrollY : 0;
    const currentTime = Date.now();
    const deltaScroll = lastScrollTop.current - currentScrollTop;
    const deltaTime = currentTime - lastScrollTime.current;

    // Calculate scroll speed (pixels per millisecond)
    const scrollSpeed = Math.abs(deltaScroll / deltaTime);

    // Determine scroll direction with adjusted thresholds
    if (currentScrollTop <= 90) {
      setScrollDirection('up');
    } else {
      if (deltaScroll > 20 && scrollSpeed > 0.2) {
        setScrollDirection('up');
      } else if (deltaScroll < -5 && scrollSpeed > 0.05) {
        setScrollDirection('down');
      }
    }
    lastScrollTop.current = currentScrollTop;
    lastScrollTime.current = currentTime;
  };

  // Throttle the scroll handler
  const throttledHandleScroll = useCallback(throttle(handleScroll, 300), [
    activeIndex,
  ]);

  useEffect(() => {
    window.addEventListener('scroll', throttledHandleScroll);
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll);
    };
  }, [throttledHandleScroll]);

  return (
    <motion.nav
      className={clsx(
        'vw:top-[64px] vw:h-[74px] sticky z-[999] w-full bg-[#eaeaea] transition-all duration-75',
        className,
        scrollDirection === 'down' &&
          'scaleY-[0]  vw:py-0 pointer-events-none opacity-[0]',
        scrollDirection === 'up' && 'scaleY-[0] vw:py-2 opacity-[1]'
      )}
    >
      <Swiper
        options={{
          initialSlide: index + 1,
          onUpdate: (swiper) => swiper.slideTo(activeIndex),
          wrapperClass: 'gap-1 justify-center',
          freeMode: true,
          modules: [FreeMode],
          slidesPerView: 6,
          loop: false,
          slideToClickedSlide: true,
          centeredSlides: false,
          ...swiperOptions,
        }}
        slidesData={gameMenuMobile}
        customRenderSlide={(_: any, index?: number) => {
          // const isActive = _.slug === activeGame;
          const isActive = activeIndex === index;

          return (
            <SwiperSlide
              key={index}
              className={clsx('vw:!h-[60px] vw:!w-[60.8px]', menuItemClass)}
            >
              <div
                onClick={() => {
                  if (_.isOpenNewTab && token) {
                    openGameProvider(_.games?.[0]);
                  }
                  // setActiveGame(_.slug);
                  handleClick?.(_.slug, index);
                  window.history.replaceState(
                    null,
                    '',
                    updateQueryStringParameters(pathname, { slug: _.slug })
                  );
                  window.scrollTo(0, 0);
                }}
                key={_.slug}
                className={clsx(
                  !isActive && 'border-2 border-white grayscale',
                  'vw:h-[60px] vw:w-[60.8px] shadow-7 w-full rounded-lg bg-[#FCFCFC] first:mt-0',
                  isActive && 'bg-linear-gray-3 shadow-6 border border-white',
                  menuItemClass
                )}
              >
                <div
                  className={clsx(
                    'vw:h-[50px] flex w-full flex-col items-center justify-center rounded-lg'
                  )}
                >
                  <div
                    className={clsx(
                      isAnimated && isActive && 'active-icon pb-0.5',
                      'relative m-auto flex h-full w-full items-center justify-center'
                    )}
                  >
                    <img
                      className={clsx(
                        !isActive && 'vw:h-[24px] vw:min-w-[24px]',
                        isActive &&
                          clsx(
                            'vw:h-[25px] vw:w-[25px] absolute m-auto',
                            isAnimated && 'active-menu-icon'
                          ),
                        imgClass
                      )}
                      style={{
                        top: '0.5px',
                        paddingBottom: '0.5px',
                      }}
                      src={_.icon}
                      alt={_.label}
                    />
                    {/*<img src='/img/menu/active/ic_GLshadow.png' />*/}
                  </div>
                  <span
                    className={clsx(
                      'vw:text-[7.5px] whitespace-pre font-bold tracking-tighter',
                      isActive && 'vw:text-[8px]',
                      textClass
                    )}
                  >
                    {t(_.label)}
                  </span>
                </div>
              </div>
            </SwiperSlide>
          );
        }}
      />
    </motion.nav>
  );
};

export default TopMenu;
