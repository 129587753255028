/* eslint-disable no-console */
import { useEffect, useState } from 'react';

// eslint-disable-next-line no-promise-executor-return
const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const preloadImage = (src: string, index: number) => {
  return new Promise<{ src?: string; index: number }>((resolve) => {
    if (src === '-1') return;
    const img = new Image();
    img.onload = function () {
      resolve({ src, index });
    };

    const errorLoadImage = () => {
      resolve({ src, index });
    };
    img.onerror = errorLoadImage;
    img.onabort = errorLoadImage;
    img.src = src;
  });
};

const preloadAudio = (src: string, index: number) => {
  return new Promise<{ src?: string; index: number }>((resolve) => {
    const audio = new Audio();
    audio.src = src;
    audio.oncanplay = function () {
      resolve({ src, index });
    };
    audio.onload = function () {
      resolve({ src, index });
    };

    const errorLoadAudio = () => {
      console.log('preloadAudio error:', src, index);
      resolve({ src, index });
    };
    audio.onerror = errorLoadAudio;
    audio.onabort = errorLoadAudio;
    audio.src = src;
  });
};

interface IPreloadProps {
  imageList?: string[];
  audioList?: string[];
  onLoadedProgressChange?: (progress: number) => void;
  maxTimeout?: number;
}

const getPromiseList = (images: string[], audios: string[]) => {
  const imagePromises = images.map((image, index) =>
    preloadImage(image, index)
  );
  const audioPromises = audios.map((audio, index) =>
    preloadAudio(audio, index)
  );

  return [...imagePromises, ...audioPromises];
};

const promiseAllWithProgressCallback = (
  promiseList: Promise<any>[],
  progressCallback?: (progress: number) => void
) => {
  let progress = 0;
  progressCallback?.(0);
  return Promise.all(
    promiseList.map((item) =>
      item.then(() => {
        progress += 1;
        progressCallback?.((progress * 100) / promiseList.length);
      })
    )
  );
};

export default function usePreloader({
  imageList = [],
  audioList = [],
  maxTimeout = 3000,
}: IPreloadProps): [boolean, number] {
  const [preloaded, setPreloaded] = useState(false);
  const [progress] = useState(0);

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      if (isCancelled) return;
      const promiseList = getPromiseList(imageList, audioList);
      await Promise.race([
        promiseAllWithProgressCallback(promiseList).catch(() => {
          return;
        }),
        wait(maxTimeout),
      ]);

      if (isCancelled) {
        return;
      }
      setPreloaded(true);
    })();
    return () => {
      isCancelled = true;
    };
  }, [audioList, imageList, maxTimeout]);

  return [preloaded, progress];
}
