import * as process from 'process';

import { gameMenuDesktop } from '@/constant/gameMenu';

//TODO:need move env file
export const EnvData = {
  baseUrl: process.env.NEXT_PUBLIC_BASE_URL,
  socketUrl: process.env.NEXT_PUBLIC_SOCKET_URL || '',
  siteId: process.env.NEXT_PUBLIC_SITE_ID_LIVE || '43478594',
  urlBoAgent:
    process.env.NEXT_PUBLIC_URL_BO_AGENT || 'https://bo-agent.l004-stg.com/',
  teleLink:
    process.env.NEXT_PUBLIC_URL_TELE ||
    'https://t.me/Siam88_test_bot?start=lulu',
};

export const siteConfig = {
  title: 'PhomBet | Nhà cái Cá cược bóng đá Uy tín - Hoàn trả vô tận 1.3%',
  description:
    'PhomBet - Website cá cược Thể thao trực tuyến, Esports, Sòng bài, Nổ hũ, Lô đề, Quay số, Keno,…đa dạng với nhiều thể loại Cá cược, Game bài',
  url: EnvData.baseUrl,
};

const modalImages = [
  '/img/modal/modal-pattern.png',
  '/img/modal/modal-bg.png',
  '/img/modal/warning.png',
  '/img/modal/error.png',
  '/img/modal/info.png',
  '/img/modal/logout.png',
  '/img/modal/success.png',
  '/img/modal/popup_1.png',
  '/img/modal/popup_2.png',
  '/img/modal/modal-banner.png',
  '/img/promotion/first-deposit.png',
  '/img/promotion/banner_2.png',
  '/img/promotion/rebate-banner.png',
  '/img/modal/lock.png',
  '/img/game/sport/frame-match.png',
  '/img/common/b.svg',
  '/img/common/web_heart_animation.png',
];

export const imageListPreloaded = modalImages
  .concat(
    gameMenuDesktop
      .map((_) => _?.games)
      .flat()
      .map((_) => _?.mobileImg)
  )
  .concat(gameMenuDesktop.map((_) => _?.mobileBanner || ''))
  .filter(Boolean);

export const imageListPreloadedDesktop = modalImages
  .concat(
    gameMenuDesktop
      .map((_) => _.games)
      .flat()
      .map((_) => [_.img, _.icon])
      .flat()
      .filter(Boolean)
  )
  .filter(Boolean);
