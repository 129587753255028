/* eslint-disable react-hooks/exhaustive-deps */
'use client';
import dayjs from 'dayjs';
import { useRouter } from 'next/navigation';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import 'intersection-observer';

import { clearLocal, getDataLocal } from '@/lib/utils';

import useClientTranslation from '@/components/hooks/useClientTranslation';
import usePreloader from '@/components/hooks/usePreloader';
import useIsMobile from '@/components/hooks/useScreenSize';

import { recentPlay } from '@/app/[lng]/(home)/components/Mobile/components/Renderer/RenderCateItemGames';
import {
  imageListPreloaded,
  imageListPreloadedDesktop,
} from '@/constant/config';
import { gameMenuMobile } from '@/constant/gameMenu';

const Initialization: React.FC = () => {
  const { currentLink } = useClientTranslation('');
  const isMobile = useIsMobile();
  const router = useRouter();
  const isMounted = useRef(false);
  usePreloader(
    isMounted.current === false
      ? {
          imageList: isMobile ? imageListPreloaded : imageListPreloadedDesktop,
        }
      : {}
  );

  useLayoutEffect(() => {
    isMounted.current = true;
    gameMenuMobile.forEach((_) => {
      router.prefetch(currentLink(`/?slug=${_.slug}`) as string);
    });
  }, []);

  useEffect(() => {
    try {
      const kycLocal = getDataLocal('KYC');
      const recentPlayLocal = getDataLocal(recentPlay);
      if (kycLocal && kycLocal?.expiredTime) {
        if (dayjs().isAfter(dayjs(Number(kycLocal?.expiredTime)))) {
          clearLocal('KYC');
        }
      }
      if (recentPlayLocal && recentPlayLocal?.expiredTime) {
        if (dayjs().isAfter(dayjs(Number(recentPlayLocal?.expiredTime)))) {
          clearLocal(recentPlay);
        }
      }
    } catch (error) {
      console.error('clear local cache failure by:', error);
    }
  }, []);
  return <></>;
};

export default React.memo(Initialization);
