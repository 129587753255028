'use client';
import { Select } from 'antd';
import React, { useMemo } from 'react';

import { mapLabelFilterProvider } from '@/lib/utils';

import useClientTranslation from '@/components/hooks/useClientTranslation';

import FilterCates from '@/app/[lng]/(home)/components/Mobile/components/FilterCates';
import { lobbyCateProvider } from '@/constant/data';

const FilterProviders: React.FC<any> = ({
  items,
  params,
  setSelectValue,
  selectValue,
  setCateDetail,
  cateDetail,
  providersActive = lobbyCateProvider,
}) => {
  const [display, partnerProvider]: any = params;
  const { t } = useClientTranslation(['HomePage', 'AccountPage'], {
    nsMode: 'fallback',
  });

  const options = useMemo(
    () =>
      (providersActive?.[display]?.partner_provider || [])?.map((_: any) => ({
        label: (
          <div className='flex items-center'>
            <span className='vw:!text-12 mr-auto w-[80%] overflow-hidden capitalize'>
              {mapLabelFilterProvider(_, { isFormat: true })}
            </span>
            <div className='rounded-lg'>
              <img
                className='logo vw:w-[22px] ml-auto'
                src={`/img/game/banner/logo-provider/${mapLabelFilterProvider(
                  _
                )}.png`}
                alt=''
              />
            </div>
          </div>
        ),
        value: _,
      })),
    [display, providersActive]
  );

  return (
    <div className='px-4'>
      <h2 className='border-main-secondary mb-3 flex items-center'>
        <span className='vw:text-16 vw:pl-2 -ml-1 border-l-2 border-l-[#AA7300] font-semibold leading-[normal] '>
          {t(items.label)}
        </span>
        <div></div>
        <div className='text-16 ml-auto font-bold'>
          <Select
            virtual={false}
            dropdownClassName='gameDropdown'
            onChange={(value) => {
              setSelectValue(value);
            }}
            value={selectValue || mapLabelFilterProvider(partnerProvider)}
            className='hot-game-select hide-tap-highlight vw:!text-12 vw:!w-[140px] vw:!h-[30px] !border-0 md:!h-[32px]'
            dropdownRender={(menu) => {
              return <div className='cursor-hand !font-bold'>{menu}</div>;
            }}
            suffixIcon={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='14'
                height='14'
                viewBox='0 0 14 14'
                fill='none'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M2.79585 4.98335C3.02366 4.75555 3.39301 4.75555 3.62081 4.98335L7 8.36254L10.3792 4.98335C10.607 4.75555 10.9763 4.75555 11.2041 4.98335C11.432 5.21116 11.432 5.5805 11.2041 5.80831L7.82496 9.1875C7.36935 9.64311 6.63065 9.64311 6.17504 9.1875L2.79585 5.80831C2.56805 5.58051 2.56805 5.21116 2.79585 4.98335Z'
                  fill='#6F767E'
                />
              </svg>
            }
            defaultValue={mapLabelFilterProvider(partnerProvider)}
            options={[
              {
                label: <span className='vw:!text-12'>{t('allProvider')}</span>,
                value: '',
              },
            ].concat(options)}
          ></Select>
        </div>
      </h2>
      <div className='relative pb-2'>
        <FilterCates
          items={items}
          setCateDetail={setCateDetail}
          cateDetail={cateDetail}
          // isShowAllCate={!selectValue}
        />
      </div>
    </div>
  );
};

export default React.memo(FilterProviders);
